/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import { Buffer } from 'buffer';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'requestidlecallback-polyfill';

window.global = window;
window.Buffer = Buffer;
window.process = { ...window.process, browser: true };
